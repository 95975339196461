import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ArrowIcon from "../Icons/ArrowIcon"
import TimeBackground from "../../images/creat-time.png"

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  margin-bottom: 60px;
  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const Article = styled.div`
  position: relative;

  & .gatsby-image-wrapper {
    position: relative;
    width: 100%;
    height: 243px;
  }
  & h2 {
    font-size: 24px;
    line-height: 29px;
    color: #062c44;
    margin-top: 16px;
    margin-bottom: 13px;
    > a{
      color: #062c44;
      &:hover, &:focus{
        color:#ed602b;
      }
    }
  }

  hr {
    margin-bottom: 12px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    color: #010920;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 30px;
  }
`

const CreatTime = styled.div`
  position: relative;
  width: fit-content;
  background: url(${props => props.bg}) top right;
  background-size: cover;
  padding: 6px 15px 6px 10px;
  margin-top: -15px;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  color: white;
  z-index: 20;
`

const ViewDetail = styled.div`
  position: absolute;
  bottom: -5px;
  right: 5px;
  & :hover {
    right: 0px;
    cursor: pointer;
  }
`

const ArticleList = ({ data }) => {
  return (
    <ArticleGrid>
      {data.map((article, i) => {
        const temp = article.node.title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
        const titleUrl = temp.split(" ").join("-").toLowerCase();
        const url = titleUrl.slice(-1) === "-" ? titleUrl.slice(0, -1) : titleUrl;

        return (
          <Article key={i}>
            <Link to={`/blog/${url}/`}>
              <Img
                fluid={article.node.tumbnail.fluid}
                alt={`thumbnail-${article.node.title}`}
              />
            </Link>
            <CreatTime bg={TimeBackground}>
              {article.node.createdAt}
            </CreatTime>
            <h3>{article.node.category.name}</h3><hr />
            <h2><Link to={`/blog/${url}/`}>{article.node.title}</Link></h2>
            <p>{article.node.description?.description} </p>
            <ViewDetail>
              <Link to={`/blog/${url}/`}>
                <ArrowIcon fill="#ED602B" />
              </Link>
            </ViewDetail>
          </Article>
        )
      })}
    </ArticleGrid>
  )
}

export default ArticleList
