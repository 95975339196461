import React, { Fragment, useEffect, useState } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import {
  SectionPage,
  Container,
  BreadCrumb,
  MainPanel,
  LeftPanel,
  RightPanel,
  SectionPageTitle,
  SectionDescription,
} from "../components/Section"
import {
  SidebarSticky,
  SidebarForm,
  SidebarFormHeader,
  SidebarFormTitle,
  SidebarFormBody,
  SidebarCard,
  SidebarCardHeader,
  SidebarCardTitle,
  SidebarCardBody,
} from "../components/Sidebar"
import {
  MediaList,
  Media,
  MediaThumb,
  MediaBody,
  ListBadge,
  ListItemBadge,
  Badge,
} from "../components/ListGroup"
import QuickContactForm from "../components/QuickContactForm"
import ArticleList from "../components/ArticleList"
import Pagination from "../components/Pagination"
import Img from "gatsby-image"
import { extractStaticUrlStr } from "../utils/string"

const NoContent = styled.h3`
  text-align: center;
`

const BlogPage = ({ data, location, formName }) => {
  const {
    allContentfulArticle: { edges: totalArticles },
    allContentfulArticleCategory: { edges: categories },
  } = data
  const [isCurrent, setIsCurrent] = useState(
    location?.state?.category || "All Blog"
  )
  const [articlesData, setArticlesData] = useState([])
  const [showArticles, setShowArticles] = useState([])
  const pageSize = 6
  const recentPosts = totalArticles.slice(0, 3)

  useEffect(() => {
    if (isCurrent === "All Blog") {
      setArticlesData(totalArticles)
    } else {
      let articles = []
      totalArticles.forEach(item => {
        if (item.node.category.name === isCurrent) {
          articles.push(item)
        }
      })
      setArticlesData(articles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrent])

  useEffect(() => {
    if (articlesData.length <= pageSize) {
      setShowArticles(articlesData)
    }
  }, [articlesData])

  const articleCount = categoryName => {
    let count = 0
    totalArticles.forEach(item => {
      if (item.node.category.name === categoryName) {
        count++
      }
    })
    return count
  }

  const refreshPage = pageNo => {
    const startIndex = (pageNo - 1) * pageSize
    const endIndex = Math.min(startIndex + pageSize, articlesData.length)
    const pageData = articlesData.slice(startIndex, endIndex)
    setShowArticles([...pageData])
    document
      .getElementById("list-content")
      .scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout location={location}>
      <SEO title="Metal Buildings Blog | Expert Tips, Trends, and Insights on Steel Structures" description="Explore our metal buildings blog for expert construction tips, industry trends, and everything you need to know about steel structures. Check it out!" noIndex={true} />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="140px"
        xpb="20px"
        pt="100px"
        pb="10px"
        bg="#fff">
          <Container>
          <BreadCrumb className="static"><Link to="/">Home</Link> / <span>Blogs</span></BreadCrumb>
          <SectionPageTitle textAlign="center">Blogs</SectionPageTitle>
          <SectionDescription  maxWidth="920px"><p>Check out our most recent blog posts for valuable information about steel buildings and unique storage solutions. With everything from organization tips to information about standards and regulations across the country our blog can be a great tool to help keep you informed and entertained.</p></SectionDescription>
        </Container>
      </SectionPage>
      <SectionPage
          ept="80px"
          epb="80px"
          xpt="40px"
          xpb="40px"
          pt="30px"
          pb="30px"
          bg="#F4FBFF" id="list-content">
          <Container>
            <MainPanel>
              <LeftPanel>
                {articlesData.length > 0 ? (
                  <Fragment>
                    <ArticleList data={showArticles} />
                    {articlesData.length > pageSize && (
                      <Pagination
                        pages={Math.ceil(articlesData.length / pageSize)}
                        onChange={refreshPage}
                        refresh={isCurrent}
                        contentRef={document.getElementById("list-content")}
                      />
                    )}
                  </Fragment>
                ) : (
                  <NoContent>No Related Blog Content</NoContent>
                )}
              </LeftPanel>
              <RightPanel>
                <SidebarSticky className="sidebar isSticky">
                  <SidebarCard>
                    <SidebarCardHeader>
                      <SidebarCardTitle>Recent Posts</SidebarCardTitle>
                    </SidebarCardHeader>
                    <SidebarCardBody>
                      <MediaList className="media-list">
                        {recentPosts.map((post, i) => (
                          <Media className="media" key={i}>
                            <MediaThumb className="media-thumb">
                              <Img
                                fluid={post.node.tumbnail.fluid}
                                alt={`thumbnail-${post.node.title}`}
                              />
                            </MediaThumb>
                            <MediaBody className="media-body">
                              <Link
                                to={`/blog/${extractStaticUrlStr(
                                  post.node.title
                                )}/`}
                              >
                                <p>{post.node.title}</p>
                              </Link>
                              <small>{post.node.createdAt}</small>
                            </MediaBody>
                          </Media>
                        ))}
                      </MediaList>
                    </SidebarCardBody>
                  </SidebarCard>
                  <SidebarCard>
                    <SidebarCardHeader>
                      <SidebarCardTitle>Blog Categories</SidebarCardTitle>
                    </SidebarCardHeader>
                    <SidebarCardBody>
                      <ListBadge className="list-badge">
                        <ListItemBadge className="list-item">
                          <Badge
                            bg={isCurrent === "All Blog" ? "#ED602B" : "white"}
                            color={isCurrent === "All Blog" ? "white" : "#140F0F"}
                            onClick={() => setIsCurrent("All Blog")}
                          >
                            <span className="tag">All Blog</span>
                            <span className="count">{totalArticles.length}</span>
                          </Badge>
                        </ListItemBadge>
                        {categories.map((category, i) => (
                          <ListItemBadge className="list-item" key={i}>
                            <Badge
                              bg={
                                isCurrent === category.node.name
                                  ? "#ED602B"
                                  : "white"
                              }
                              color={
                                isCurrent === category.node.name
                                  ? "white"
                                  : "#140F0F"
                              }
                              onClick={() => setIsCurrent(category.node.name)}
                            >
                              <span className="tag">{category.node.name}</span>
                              <span className="count">
                                {articleCount(category.node.name)}
                              </span>
                            </Badge>
                          </ListItemBadge>
                        ))}
                      </ListBadge>
                    </SidebarCardBody>
                  </SidebarCard>
                  <SidebarForm>
                    <SidebarFormHeader>
                      <SidebarFormTitle>GET IN TOUCH</SidebarFormTitle>
                    </SidebarFormHeader>
                    <SidebarFormBody>
                      <QuickContactForm
                        location={location}
                        formName={formName ? formName : "Quick Blog Form"}
                      />
                    </SidebarFormBody>
                  </SidebarForm>
                </SidebarSticky>
              </RightPanel>
            </MainPanel>
          </Container>
      </SectionPage>
      <CallToAction />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query BlogPageQuery {
    allContentfulArticle(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          tumbnail {
            fluid(maxWidth: 1500) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          description {
            description
          }
          category {
            name
          }
          createdAt(formatString: "D MMM YYYY")
        }
      }
    }
    allContentfulArticleCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`
